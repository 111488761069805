import {
  Box,
  BoxProps,
  Heading,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import GatsbyLink from 'gatsby-link';
import React from 'react';

type Props = BoxProps & {
  date?: string;
  isHome?: boolean;
  lang?: string;
  slug: string;
  text?: string;
  timeToRead?: number;
  title: string;
};

export function BlogItem({
  date,
  isHome,
  lang,
  slug,
  text,
  timeToRead,
  title,
  ...rest
}: Props) {
  const color = useColorModeValue('sand', 'gold');
  const postDate =
    date &&
    new Date(date).toLocaleDateString(lang, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  return (
    <Box {...rest}>
      <Heading
        as="h4"
        fontSize={['xl', '2xl']}
        fontWeight="black"
        mb={[2, 0]}
        textAlign={['center', 'left']}
      >
        <Link as={GatsbyLink} _hover={{ color }} to={slug}>
          {title}
        </Link>
      </Heading>

      {date && (
        <Text
          color="gray.500"
          fontSize="1rem"
          m={0}
          textAlign={['center', 'left']}
        >
          {`${postDate} - ${timeToRead} min`}
        </Text>
      )}

      <Text
        fontSize={isHome ? 'sm' : 'md'}
        opacity={0.7}
        textAlign={['center', 'left']}
      >
        {text}
      </Text>
    </Box>
  );
}
