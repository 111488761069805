import { Divider, Flex } from '@chakra-ui/react';
import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';
import { BlogItem } from '../components/BlogItem';
import { Content } from '../components/Content';
import Layout from '../components/Layout';
import { useTranslation } from '../contexts/LanguageContext';

type Post = {
  date: string;
  lang: string;
  slug: string;
  thumbnail: string;
  title: string;
};

type Edge = {
  node: {
    id: string;
    excerpt: string;
    frontmatter: Post;
    timeToRead: number;
  };
};

type Props = PageProps & {
  data: {
    allMarkdownRemark: { edges: Edge[] };
  };
};

export default function Articles({ data, pageContext }: Props) {
  const { t, locale } = useTranslation();
  const prefix = locale === 'en' ? '/en' : '';
  const { numPages } = pageContext;

  const posts = data.allMarkdownRemark.edges;
  const filteredPosts = posts.filter((edge) =>
    edge.node.frontmatter.lang.includes(locale)
  );

  return (
    <Layout title={t('articles')} hideHero>
      <Content>
        {filteredPosts.map(({ node }) => {
          const { id, excerpt, timeToRead, frontmatter } = node;
          const { title, slug, date, lang } = frontmatter;

          return (
            <BlogItem
              date={date}
              key={id}
              lang={lang}
              my="4rem"
              slug={`${prefix}/post${slug}`}
              text={excerpt}
              timeToRead={timeToRead}
              title={title}
            />
          );
        })}

        <Divider variant="dashed" w="60vw" m="4rem auto" />

        <Flex
          className="pagination"
          fontSize="xl"
          fontWeight="black"
          gap={6}
          justifyContent="center"
          mb={['10rem', 0]}
        >
          {Array.from({ length: numPages }, (_, i) => (
            <Link
              activeClassName="active"
              key={`pagination-number${i + 1}`}
              to={`/articles/${i === 0 ? '' : i + 1}`}
            >
              {i + 1}
            </Link>
          ))}
        </Flex>
      </Content>
    </Layout>
  );
}

export const articlesQuery = graphql`
  query articlesQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          timeToRead
          frontmatter {
            date
            slug
            title
            lang
          }
        }
      }
    }
  }
`;
